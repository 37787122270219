export const slotsIntlIds = {
  slotsList: {
    header: {
      createSlotsButton: {
        label: 'slotsIntlIds.slotsList.header.createSlotsButton.label',
      },
      divulgateSlotsButton: {
        label: 'slotsIntlIds.slotsList.header.divulgateSlotsButton.label',
      },
    },
  },

  createSlots: {
    drawer: {
      title: 'slotsIntlIds.createSlots.drawer.title',
      closeDrawerButton: {
        label: 'slotsIntlIds.createSlots.drawer.closeDrawerButton.label',
      },
      content: {
        tabs: {
          importSlots: {
            label:
              'slotsIntlIds.createSlots.drawer.content.tabs.importSlots.label',
          },
          createUnitarySlot: {
            label:
              'slotsIntlIds.createSlots.drawer.content.tabs.createUnitarySlot.label',
          },
        },
        tabPanels: {
          importSlots: {
            title:
              'slotsIntlIds.createSlots.drawer.content.tabPanels.importSlots.title',
            description:
              'slotsIntlIds.createSlots.drawer.content.tabPanels.importSlots.description',

            confirmImportSlotsModal: {
              title:
                'slotsIntlIds.createSlots.drawer.content.tabPanels.importSlots.confirmImportSlotsModal.title',
              description:
                'slotsIntlIds.createSlots.drawer.content.tabPanels.importSlots.confirmImportSlotsModal.description',
              confirmAndImportButton: {
                label:
                  'slotsIntlIds.createSlots.drawer.content.tabPanels.importSlots.confirmImportSlotsModal.confirmAndImportButton.label',
              },
              cancelButton: {
                label:
                  'slotsIntlIds.createSlots.drawer.content.tabPanels.importSlots.confirmImportSlotsModal.cancelButton.label',
              },
            },

            cancelImportSlotsModal: {
              title:
                'slotsIntlIds.createSlots.drawer.content.tabPanels.importSlots.cancelImportSlotsModal.title',
              description:
                'slotsIntlIds.createSlots.drawer.content.tabPanels.importSlots.cancelImportSlotsModal.description',
              stayAndContinueButton: {
                label:
                  'slotsIntlIds.createSlots.drawer.content.tabPanels.importSlots.cancelImportSlotsModal.stayAndContinueButton.label',
              },
              leaveButton: {
                label:
                  'slotsIntlIds.createSlots.drawer.content.tabPanels.importSlots.cancelImportSlotsModal.leaveButton.label',
              },
            },

            footer: {
              confirmImportSlotsButton: {
                label:
                  'slotsIntlIds.createSlots.drawer.content.tabPanels.importSlots.footer.confirmImportSlotsButton.label',
              },
              cancelImportSlotsButton: {
                label:
                  'slotsIntlIds.createSlots.drawer.content.tabPanels.importSlots.footer.cancelImportSlotsButton.label',
              },
            },

            onImportSlots: {
              success: {
                title:
                  'slotsIntlIds.createSlots.drawer.content.tabPanels.importSlots.onImportSlots.success.title',
                description:
                  'slotsIntlIds.createSlots.drawer.content.tabPanels.importSlots.onImportSlots.success.description',
              },
              error: {
                title:
                  'slotsIntlIds.createSlots.drawer.content.tabPanels.importSlots.onImportSlots.error.title',
                description:
                  'slotsIntlIds.createSlots.drawer.content.tabPanels.importSlots.onImportSlots.error.description',
              },
            },
          },
        },
      },
    },
  },

  divulgateSlots: {
    drawer: {
      title: 'slotsIntlIds.divulgateSlots.drawer.title',
      closeDrawerButton: {
        label: 'slotsIntlIds.divulgateSlots.drawer.closeDrawerButton.label',
      },
      content: {
        tabs: {
          importDivulgationRules: {
            label:
              'slotsIntlIds.divulgateSlots.drawer.content.tabs.importDivulgationRules.label',
          },
          divulgateSlots: {
            label:
              'slotsIntlIds.divulgateSlots.drawer.content.tabs.divulgateSlots.label',
          },
        },
        tabPanels: {
          importDivulgationRules: {
            title:
              'slotsIntlIds.divulgateSlots.drawer.content.tabPanels.importDivulgationRules.title',
            description:
              'slotsIntlIds.divulgateSlots.drawer.content.tabPanels.importDivulgationRules.description',

            footer: {
              confirmImportDivulgationRulesButton: {
                label:
                  'slotsIntlIds.divulgateSlots.drawer.content.tabPanels.importDivulgationRules.footer.confirmImportDivulgationRulesButton.label',
              },
              cancelImportDivulgationRulesButton: {
                label:
                  'slotsIntlIds.divulgateSlots.drawer.content.tabPanels.importDivulgationRules.footer.cancelImportDivulgationRulesButton.label',
              },
            },

            onImportDivulgationRules: {
              success: {
                title:
                  'slotsIntlIds.divulgateSlots.drawer.content.tabPanels.importDivulgationRules.onImportDivulgationRules.success.title',
                description:
                  'slotsIntlIds.divulgateSlots.drawer.content.tabPanels.importDivulgationRules.onImportDivulgationRules.success.description',
              },
              error: {
                title:
                  'slotsIntlIds.divulgateSlots.drawer.content.tabPanels.importDivulgationRules.onImportDivulgationRules.error.title',
                description:
                  'slotsIntlIds.divulgateSlots.drawer.content.tabPanels.importDivulgationRules.onImportDivulgationRules.error.description',
              },
            },
          },
          divulgateSlots: {
            title:
              'slotsIntlIds.divulgateSlots.drawer.content.tabPanels.divulgateSlots.title',
            description:
              'slotsIntlIds.divulgateSlots.drawer.content.tabPanels.divulgateSlots.description',
            divulgationRulesStatus: {
              noRulesImported: {
                label:
                  'slotsIntlIds.divulgateSlots.drawer.content.tabPanels.divulgateSlots.divulgationRulesStatus.noRulesImported.label',
              },
              rulesBeingImported: {
                label:
                  'slotsIntlIds.divulgateSlots.drawer.content.tabPanels.divulgateSlots.divulgationRulesStatus.rulesBeingImported.label',
              },
              lastRulesImportedAt: {
                label:
                  'slotsIntlIds.divulgateSlots.drawer.content.tabPanels.divulgateSlots.divulgationRulesStatus.lastRulesImportedAt.label',
              },
            },
            divulgationRulesSummary: {
              title:
                'slotsIntlIds.divulgateSlots.drawer.content.tabPanels.divulgateSlots.divulgationRulesSummary.title',
              slotsDates: {
                label:
                  'slotsIntlIds.divulgateSlots.drawer.content.tabPanels.divulgateSlots.divulgationRulesSummary.slotsDates.label',
                value:
                  'slotsIntlIds.divulgateSlots.drawer.content.tabPanels.divulgateSlots.divulgationRulesSummary.slotsDates.value',
              },
              totalDates: {
                label:
                  'slotsIntlIds.divulgateSlots.drawer.content.tabPanels.divulgateSlots.divulgationRulesSummary.totalDates.label',
                value:
                  'slotsIntlIds.divulgateSlots.drawer.content.tabPanels.divulgateSlots.divulgationRulesSummary.totalDates.value',
              },
              totalSlots: {
                label:
                  'slotsIntlIds.divulgateSlots.drawer.content.tabPanels.divulgateSlots.divulgationRulesSummary.totalSlots.label',
                value:
                  'slotsIntlIds.divulgateSlots.drawer.content.tabPanels.divulgateSlots.divulgationRulesSummary.totalSlots.value',
              },
              totalHubs: {
                label:
                  'slotsIntlIds.divulgateSlots.drawer.content.tabPanels.divulgateSlots.divulgationRulesSummary.totalHubs.label',
                value:
                  'slotsIntlIds.divulgateSlots.drawer.content.tabPanels.divulgateSlots.divulgationRulesSummary.totalHubs.value',
              },
              shifts: {
                label:
                  'slotsIntlIds.divulgateSlots.drawer.content.tabPanels.divulgateSlots.divulgationRulesSummary.shifts.label',
              },
              modalities: {
                label:
                  'slotsIntlIds.divulgateSlots.drawer.content.tabPanels.divulgateSlots.divulgationRulesSummary.modalities.label',
              },
              medals: {
                label:
                  'slotsIntlIds.divulgateSlots.drawer.content.tabPanels.divulgateSlots.divulgationRulesSummary.medals.label',
              },
            },
            footer: {
              confirmDivulgateSlotsButton: {
                label:
                  'slotsIntlIds.divulgateSlots.drawer.content.tabPanels.divulgateSlots.footer.confirmDivulgateSlotsButton.label',
              },
              cancelDivulgateSlotsButton: {
                label:
                  'slotsIntlIds.divulgateSlots.drawer.content.tabPanels.divulgateSlots.footer.cancelDivulgateSlotsButton.label',
              },
            },

            confirmDivulgatePlanningModal: {
              title:
                'slotsIntlIds.divulgateSlots.drawer.content.tabPanels.divulgateSlots.confirmDivulgatePlanningModal.title',
              description:
                'slotsIntlIds.divulgateSlots.drawer.content.tabPanels.divulgateSlots.confirmDivulgatePlanningModal.description',
              confirmAndDivulgateButton: {
                label:
                  'slotsIntlIds.divulgateSlots.drawer.content.tabPanels.divulgateSlots.confirmDivulgatePlanningModal.confirmAndDivulgateButton.label',
              },
              cancelButton: {
                label:
                  'slotsIntlIds.divulgateSlots.drawer.content.tabPanels.divulgateSlots.confirmDivulgatePlanningModal.cancelButton.label',
              },
            },

            onDivulgatePlanning: {
              success: {
                title:
                  'slotsIntlIds.divulgateSlots.drawer.content.tabPanels.divulgateSlots.onDivulgatePlanning.success.title',
                description:
                  'slotsIntlIds.divulgateSlots.drawer.content.tabPanels.divulgateSlots.onDivulgatePlanning.success.description',
              },
              error: {
                title:
                  'slotsIntlIds.divulgateSlots.drawer.content.tabPanels.divulgateSlots.onDivulgatePlanning.error.title',
                description:
                  'slotsIntlIds.divulgateSlots.drawer.content.tabPanels.divulgateSlots.onDivulgatePlanning.error.description',
              },
            },
          },
        },
      },
    },
  },

  divulgationSteps: {
    label:
      'slotsIntlIds.createSlots.drawer.content.tabPanels.importSlots.steps.label',
    chevron: {
      label:
        'slotsIntlIds.createSlots.drawer.content.tabPanels.importSlots.steps.chevron.label',
    },
    createSlot: {
      label:
        'slotsIntlIds.createSlots.drawer.content.tabPanels.importSlots.steps.createSlot.label',
      description:
        'slotsIntlIds.createSlots.drawer.content.tabPanels.importSlots.steps.createSlot.description',
    },
    importRules: {
      label:
        'slotsIntlIds.createSlots.drawer.content.tabPanels.importSlots.steps.importRules.label',
      description:
        'slotsIntlIds.createSlots.drawer.content.tabPanels.importSlots.steps.importRules.description',
    },
    divulgateSlots: {
      label:
        'slotsIntlIds.createSlots.drawer.content.tabPanels.importSlots.steps.divulgateSlots.label',
      description:
        'slotsIntlIds.createSlots.drawer.content.tabPanels.importSlots.steps.divulgateSlots.description',
    },
  },
};
