import { dashboardLayoutIntlIds } from './dashboard-layout';

export const dashboardLayoutLangEsMx = {
  [dashboardLayoutIntlIds.sidebar.navigation.scope.label]:
    '{scope, select, planning {Planificación} other {}}',

  [dashboardLayoutIntlIds.sidebar.navigation.items.deliveries.label]:
    'Entregas: Lista',
  [dashboardLayoutIntlIds.sidebar.navigation.items.riders.availability.label]:
    'Rider: Disponibilidad',
  [dashboardLayoutIntlIds.sidebar.navigation.items.riders.management.label]:
    'Rider: Lista',
  [dashboardLayoutIntlIds.sidebar.navigation.items.riders.shifts.label]:
    'Rider: Turnos',
  [dashboardLayoutIntlIds.sidebar.navigation.items.riders.slots.label]:
    'Rider: Vacantes',
  [dashboardLayoutIntlIds.sidebar.navigation.items.trips.label]:
    'Viajes: Lista',
  [dashboardLayoutIntlIds.sidebar.navigation.items.hubs.label]: 'Hubs: Lista',

  [dashboardLayoutIntlIds.sidebar.profile.userName]: 'Desconocido',
  [dashboardLayoutIntlIds.sidebar.profile.items.signOut]: 'Salír',
};
