import { Icon as ChakraIcon } from '@chakra-ui/react';
import { forwardRef, LegacyRef } from 'react';

import { IconProps } from './icon.types';

export default forwardRef(function Icon(
  props: IconProps,
  ref: LegacyRef<SVGSVGElement>
) {
  return <ChakraIcon {...props} ref={ref} />;
});
