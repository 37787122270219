import { slotsIntlIds } from './slots.ids';

export const slotsLangPtBr = {
  [slotsIntlIds.slotsList.header.createSlotsButton.label]: 'Criar vagas',
  [slotsIntlIds.slotsList.header.divulgateSlotsButton.label]: 'Divulgar vagas',

  [slotsIntlIds.createSlots.drawer.title]: 'Criar vagas',
  [slotsIntlIds.createSlots.drawer.closeDrawerButton.label]: 'Fechar drawer',

  [slotsIntlIds.createSlots.drawer.content.tabs.importSlots.label]:
    'Importar vagas',
  [slotsIntlIds.createSlots.drawer.content.tabs.createUnitarySlot.label]:
    'Criar uma vaga',

  [slotsIntlIds.createSlots.drawer.content.tabPanels.importSlots.title]:
    'Importar vagas',
  [slotsIntlIds.createSlots.drawer.content.tabPanels.importSlots
    .description]: `Faça o upload da planilha .csv preenchida com as vagas que deseja criar. Os campos data, hub, turno e quantidade de vagas são obrigatórios, então planilhas com outras colunas não serão aceitas.\nConsulte os nomes dos turnos disponíveis <link>aqui</link> para preencher a planilha e, se precisar, crie um novo turno antes de criar as vagas.`,

  [slotsIntlIds.createSlots.drawer.content.tabPanels.importSlots
    .confirmImportSlotsModal.title]:
    'Confirma a importação da planilha de vagas?',
  [slotsIntlIds.createSlots.drawer.content.tabPanels.importSlots
    .confirmImportSlotsModal.description]:
    'A planilha será importada e processada. Após a validação, as vagas serão criadas conforme os dados enviados. Esta ação não poderá ser desfeita.',
  [slotsIntlIds.createSlots.drawer.content.tabPanels.importSlots
    .confirmImportSlotsModal.confirmAndImportButton.label]:
    'Confirmar e importar',
  [slotsIntlIds.createSlots.drawer.content.tabPanels.importSlots
    .confirmImportSlotsModal.cancelButton.label]: 'Cancelar',

  [slotsIntlIds.createSlots.drawer.content.tabPanels.importSlots
    .cancelImportSlotsModal.title]: 'Tem certeza de que deseja sair?',
  [slotsIntlIds.createSlots.drawer.content.tabPanels.importSlots
    .cancelImportSlotsModal.description]:
    'Suas alterações não foram salvas e você precisará repetí-las desde o início.',
  [slotsIntlIds.createSlots.drawer.content.tabPanels.importSlots
    .cancelImportSlotsModal.stayAndContinueButton.label]: 'Ficar e continuar',
  [slotsIntlIds.createSlots.drawer.content.tabPanels.importSlots
    .cancelImportSlotsModal.leaveButton.label]: 'Sair',

  [slotsIntlIds.createSlots.drawer.content.tabPanels.importSlots.footer
    .cancelImportSlotsButton.label]: 'Cancelar',
  [slotsIntlIds.createSlots.drawer.content.tabPanels.importSlots.footer
    .confirmImportSlotsButton.label]: 'Importar vagas',

  [slotsIntlIds.createSlots.drawer.content.tabPanels.importSlots.onImportSlots
    .success.title]: 'Vagas criadas e disponíveis para a divulgação!',
  [slotsIntlIds.createSlots.drawer.content.tabPanels.importSlots.onImportSlots
    .success.description]:
    'Agora basta importar as regras de divulgação para que as pessoas Riders possam reservá-las',
  [slotsIntlIds.createSlots.drawer.content.tabPanels.importSlots.onImportSlots
    .error.title]: 'Erro ao criar vagas para divulgação!',
  [slotsIntlIds.createSlots.drawer.content.tabPanels.importSlots.onImportSlots
    .error.description]:
    'Houve um problema ao criar as vagas. Verifique os dados importados e tente novamente',

  [slotsIntlIds.divulgateSlots.drawer.title]: 'Divulgar vagas',
  [slotsIntlIds.divulgateSlots.drawer.closeDrawerButton.label]: 'Fechar drawer',

  [slotsIntlIds.divulgateSlots.drawer.content.tabs.importDivulgationRules
    .label]: 'Importar regras de divulgação',
  [slotsIntlIds.divulgateSlots.drawer.content.tabs.divulgateSlots.label]:
    'Divulgar vagas',

  [slotsIntlIds.divulgateSlots.drawer.content.tabPanels.importDivulgationRules
    .title]: 'Importar as regras de divulgação',
  [slotsIntlIds.divulgateSlots.drawer.content.tabPanels.importDivulgationRules
    .description]: `Faça o upload da planilha .csv preenchida com as regras de divulgação que deseja divulgar. Os campos data, hub, turno, modal, medalha e quantidade de vagas são obrigatórios, então planilhas com outras colunas não serão aceitas.
    \nConsulte as vagas criadas <link>aqui</link> para preencher a planilha e, se precisar, crie novas vagas e adicione-as na planilha antes de importá-la.`,
  [slotsIntlIds.divulgateSlots.drawer.content.tabPanels.importDivulgationRules
    .footer.cancelImportDivulgationRulesButton.label]: 'Cancelar',
  [slotsIntlIds.divulgateSlots.drawer.content.tabPanels.importDivulgationRules
    .footer.confirmImportDivulgationRulesButton.label]: 'Importar regras',

  [slotsIntlIds.divulgateSlots.drawer.content.tabPanels.importDivulgationRules
    .onImportDivulgationRules.success.title]:
    'Regras de divulgação criadas e disponíveis para a divulgação!',
  [slotsIntlIds.divulgateSlots.drawer.content.tabPanels.importDivulgationRules
    .onImportDivulgationRules.success.description]:
    'Agora basta divulgá-las para que as pessoas Riders possam reservá-las',
  [slotsIntlIds.divulgateSlots.drawer.content.tabPanels.importDivulgationRules
    .onImportDivulgationRules.error.title]:
    'Erro ao criar regras de divulgação!',
  [slotsIntlIds.divulgateSlots.drawer.content.tabPanels.importDivulgationRules
    .onImportDivulgationRules.error.description]:
    'Houve um problema ao criar as regras de divulgação. Verifique os dados importados e tente novamente',

  [slotsIntlIds.divulgateSlots.drawer.content.tabPanels.divulgateSlots.title]:
    'Divulgar vagas',
  [slotsIntlIds.divulgateSlots.drawer.content.tabPanels.divulgateSlots
    .description]:
    'Confira as regras de divulgação vigentes antes de confirmar o disparo das vagas para as pessoas Riders e, se precisar mudá-las, importe uma nova planilha com as vagas e regras de divulgação que deseja.',
  [slotsIntlIds.divulgateSlots.drawer.content.tabPanels.divulgateSlots
    .divulgationRulesStatus.noRulesImported.label]:
    'Não há regras de divulgação criadas',
  [slotsIntlIds.divulgateSlots.drawer.content.tabPanels.divulgateSlots
    .divulgationRulesStatus.rulesBeingImported.label]:
    'Aguarde! Regras de divulgação sendo atualizadas',
  [slotsIntlIds.divulgateSlots.drawer.content.tabPanels.divulgateSlots
    .divulgationRulesStatus.lastRulesImportedAt.label]:
    'Último upload realizado em {date} às {time}',

  [slotsIntlIds.divulgateSlots.drawer.content.tabPanels.divulgateSlots
    .divulgationRulesSummary.title]: 'Regras de divulgação',
  [slotsIntlIds.divulgateSlots.drawer.content.tabPanels.divulgateSlots
    .divulgationRulesSummary.slotsDates.label]: 'Datas das vagas',
  [slotsIntlIds.divulgateSlots.drawer.content.tabPanels.divulgateSlots
    .divulgationRulesSummary.slotsDates.value]: 'de {month}',
  [slotsIntlIds.divulgateSlots.drawer.content.tabPanels.divulgateSlots
    .divulgationRulesSummary.totalDates.label]: 'Datas totais',
  [slotsIntlIds.divulgateSlots.drawer.content.tabPanels.divulgateSlots
    .divulgationRulesSummary.totalDates.value]: `{totalDates, plural,
      =0 {# dias}
      one {# dia}
      other {# dias diferentes}
    }`,
  [slotsIntlIds.divulgateSlots.drawer.content.tabPanels.divulgateSlots
    .divulgationRulesSummary.totalSlots.label]: 'Vagas totais',
  [slotsIntlIds.divulgateSlots.drawer.content.tabPanels.divulgateSlots
    .divulgationRulesSummary.totalSlots.value]: `{totalSlots, plural,
      =0 {# vagas}
      one {# vaga}
      other {# vagas}
    }`,
  [slotsIntlIds.divulgateSlots.drawer.content.tabPanels.divulgateSlots
    .divulgationRulesSummary.totalHubs.label]: 'Hubs totais',
  [slotsIntlIds.divulgateSlots.drawer.content.tabPanels.divulgateSlots
    .divulgationRulesSummary.totalHubs.value]: `{totalHubs, plural,
      =0 {# hubs}
      one {# hub}
      other {# hubs}
    }`,
  [slotsIntlIds.divulgateSlots.drawer.content.tabPanels.divulgateSlots
    .divulgationRulesSummary.shifts.label]: 'Turnos',
  [slotsIntlIds.divulgateSlots.drawer.content.tabPanels.divulgateSlots
    .divulgationRulesSummary.modalities.label]: 'Modais',
  [slotsIntlIds.divulgateSlots.drawer.content.tabPanels.divulgateSlots
    .divulgationRulesSummary.medals.label]: 'Medalhas',

  [slotsIntlIds.divulgateSlots.drawer.content.tabPanels.divulgateSlots.footer
    .cancelDivulgateSlotsButton.label]: 'Cancelar',
  [slotsIntlIds.divulgateSlots.drawer.content.tabPanels.divulgateSlots.footer
    .confirmDivulgateSlotsButton.label]: 'Divulgar vagas',

  [slotsIntlIds.divulgateSlots.drawer.content.tabPanels.divulgateSlots
    .confirmDivulgatePlanningModal.title]: 'Confirmar a divulgação das vagas?',
  [slotsIntlIds.divulgateSlots.drawer.content.tabPanels.divulgateSlots
    .confirmDivulgatePlanningModal.description]:
    'Ao confirmar, as vagas serão divulgadas e disponibilizadas para reserva. As pessoas Riders do segmento serão notificadas.\nAconselhamos que revise antes de confirmar.',
  [slotsIntlIds.divulgateSlots.drawer.content.tabPanels.divulgateSlots
    .confirmDivulgatePlanningModal.confirmAndDivulgateButton.label]:
    'Confirmar e divulgar',
  [slotsIntlIds.divulgateSlots.drawer.content.tabPanels.divulgateSlots
    .confirmDivulgatePlanningModal.cancelButton.label]: 'Cancelar',

  [slotsIntlIds.divulgateSlots.drawer.content.tabPanels.divulgateSlots
    .onDivulgatePlanning.success.title]:
    'Divulgação de vagas realizada com sucesso!',
  [slotsIntlIds.divulgateSlots.drawer.content.tabPanels.divulgateSlots
    .onDivulgatePlanning.success.description]:
    'Agora basta aguardar as pessoas Riders reservarem',
  [slotsIntlIds.divulgateSlots.drawer.content.tabPanels.divulgateSlots
    .onDivulgatePlanning.error.title]: 'Erro ao divulgar vagas!',
  [slotsIntlIds.divulgateSlots.drawer.content.tabPanels.divulgateSlots
    .onDivulgatePlanning.error.description]: 'Por favor, tente novamente',

  [slotsIntlIds.divulgationSteps.label]: 'Siga as etapas de divulgação',
  [slotsIntlIds.divulgationSteps.chevron.label]: 'Seta para a direita',
  [slotsIntlIds.divulgationSteps.createSlot.label]: 'Crie as vagas',
  [slotsIntlIds.divulgationSteps.createSlot.description]:
    'Importe uma planilha ou crie manualmente vagas sem atribuição',
  [slotsIntlIds.divulgationSteps.importRules.label]: 'Importe as regras',
  [slotsIntlIds.divulgationSteps.importRules.description]:
    'Importe uma planilha com as vagas criadas e cada regra de divulgação',
  [slotsIntlIds.divulgationSteps.divulgateSlots.label]: 'Divulgue as vagas',
  [slotsIntlIds.divulgationSteps.divulgateSlots.description]:
    'Confira as regras e confirme a divulgação para as pessoas Riders',
};
