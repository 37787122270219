export const uaaIntlIds = {
  signIn: {
    welcomeMessage: 'uaaIntlIds.signIn.welcomeMessage',
    signInInstruction: 'uaaIntlIds.signIn.signInInstruction',
    button: {
      label: 'uaaIntlIds.signIn.button.label',
    },
    manRidingBikeAlt: 'uaaIntlIds.signIn.manRidingBikeAlt',

    qa: {
      inputs: {
        email: {
          placeholder: 'uaaIntlIds.signIn.qa.inputs.email.placeholder',
        },
        password: {
          placeholder: 'uaaIntlIds.signIn.qa.inputs.password.placeholder',
        },
      },
    },
  },
};
