import { uploadFileIntlIds } from './upload-file.ids';

export const uploadFileLangPtBr = {
  [uploadFileIntlIds.label]: 'Arraste ou busque a planilha preenchida',
  [uploadFileIntlIds.maxSize]: 'Envie planilhas com até 5 MB',

  [uploadFileIntlIds.onUpload.removeFile.label]: 'Remover arquivo',
  [uploadFileIntlIds.onUpload.processing.label]: 'Arquivo sendo carregado',
  [uploadFileIntlIds.onUpload.success.label]: 'Arquivo carregado com sucesso!',
  [uploadFileIntlIds.onUpload.exceededSize.label]:
    'Erro ao carregar o arquivo!',
  [uploadFileIntlIds.onUpload.exceededSize.error.label]:
    'O arquivo ultrapassa o limite de 5 MB. Envie arquivos menores ou divida o conteúdo em partes',
  [uploadFileIntlIds.onUpload.failed.label]: 'Erro ao carregar o arquivo!',
  [uploadFileIntlIds.onUpload.failed.error.label]:
    'Um erro inesperado aconteceu. Por favor, faça o upload novamente',
};
