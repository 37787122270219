import { slotsIntlIds } from './slots.ids';

export const slotsLangEnUs = {
  [slotsIntlIds.slotsList.header.createSlotsButton.label]: 'Create slots',
  [slotsIntlIds.slotsList.header.divulgateSlotsButton.label]: 'Divulgate slots',

  [slotsIntlIds.createSlots.drawer.title]: 'Create slots',
  [slotsIntlIds.createSlots.drawer.closeDrawerButton.label]: 'Close drawer',

  [slotsIntlIds.createSlots.drawer.content.tabs.importSlots.label]:
    'Import slots',
  [slotsIntlIds.createSlots.drawer.content.tabs.createUnitarySlot.label]:
    'Create a slot',

  [slotsIntlIds.createSlots.drawer.content.tabPanels.importSlots.title]:
    'Import slots',
  [slotsIntlIds.createSlots.drawer.content.tabPanels.importSlots
    .description]: `Upload the .csv spreadsheet filled in with the slots you wish to create. The fields date, hub, shift and number of slots are required, so spreadsheets with other columns will not be accepted.\nConsult the shift names available <link>here</link> to fill in the spreadsheet and, if necessary, create a new shift before creating the slots.`,

  [slotsIntlIds.createSlots.drawer.content.tabPanels.importSlots
    .confirmImportSlotsModal.title]:
    'Confirm the import of the slots spreadsheet?',
  [slotsIntlIds.createSlots.drawer.content.tabPanels.importSlots
    .confirmImportSlotsModal.description]:
    'The spreadsheet will be imported and processed. After validation, slots will be created according to the data sent. This action cannot be undone.',
  [slotsIntlIds.createSlots.drawer.content.tabPanels.importSlots
    .confirmImportSlotsModal.confirmAndImportButton.label]:
    'Confirm and import',
  [slotsIntlIds.createSlots.drawer.content.tabPanels.importSlots
    .confirmImportSlotsModal.cancelButton.label]: 'Cancel',

  [slotsIntlIds.createSlots.drawer.content.tabPanels.importSlots.footer
    .cancelImportSlotsButton.label]: 'Cancel',
  [slotsIntlIds.createSlots.drawer.content.tabPanels.importSlots.footer
    .confirmImportSlotsButton.label]: 'Import slots',

  [slotsIntlIds.createSlots.drawer.content.tabPanels.importSlots
    .cancelImportSlotsModal.title]: 'Are you sure you want to leave?',
  [slotsIntlIds.createSlots.drawer.content.tabPanels.importSlots
    .cancelImportSlotsModal.description]:
    'Your changes were not saved and you will need to repeat them from the beginning.',
  [slotsIntlIds.createSlots.drawer.content.tabPanels.importSlots
    .cancelImportSlotsModal.stayAndContinueButton.label]: 'Stay and continue',
  [slotsIntlIds.createSlots.drawer.content.tabPanels.importSlots
    .cancelImportSlotsModal.leaveButton.label]: 'Leave',

  [slotsIntlIds.createSlots.drawer.content.tabPanels.importSlots.onImportSlots
    .success.title]: 'Slots created and available for divulgation!',
  [slotsIntlIds.createSlots.drawer.content.tabPanels.importSlots.onImportSlots
    .success.description]:
    'Now just import the divulgation rules so that Riders can book them',
  [slotsIntlIds.createSlots.drawer.content.tabPanels.importSlots.onImportSlots
    .error.title]: 'Error creating slots for divulgation!',
  [slotsIntlIds.createSlots.drawer.content.tabPanels.importSlots.onImportSlots
    .error.description]:
    'There was a problem creating the slots. Please check the imported data and try again',

  [slotsIntlIds.divulgateSlots.drawer.title]: 'Divulgate slots',
  [slotsIntlIds.divulgateSlots.drawer.closeDrawerButton.label]: 'Close drawer',

  [slotsIntlIds.divulgateSlots.drawer.content.tabs.importDivulgationRules
    .label]: 'Import divulgation rules',
  [slotsIntlIds.divulgateSlots.drawer.content.tabs.divulgateSlots.label]:
    'Divulgate slots',

  [slotsIntlIds.divulgateSlots.drawer.content.tabPanels.importDivulgationRules
    .title]: 'Import the divulgation rules',
  [slotsIntlIds.divulgateSlots.drawer.content.tabPanels.importDivulgationRules
    .description]: `Upload the .csv spreadsheet filled in with the divulgation rules you wish to divulgate. The fields date, hub, shift, mode, medal and number of slots are required, so spreadsheets with other columns will not be accepted.
    \nConsult the slots created <link>here</link> to fill in the spreadsheet and, if necessary, create new slots and add them to the spreadsheet before importing it.`,
  [slotsIntlIds.divulgateSlots.drawer.content.tabPanels.importDivulgationRules
    .footer.cancelImportDivulgationRulesButton.label]: 'Cancel',
  [slotsIntlIds.divulgateSlots.drawer.content.tabPanels.importDivulgationRules
    .footer.confirmImportDivulgationRulesButton.label]: 'Import rules',

  [slotsIntlIds.divulgateSlots.drawer.content.tabPanels.importDivulgationRules
    .onImportDivulgationRules.success.title]:
    'Divulgation rules created and available for divulgation!',
  [slotsIntlIds.divulgateSlots.drawer.content.tabPanels.importDivulgationRules
    .onImportDivulgationRules.success.description]:
    'Now just divulgate them so Riders can book them',
  [slotsIntlIds.divulgateSlots.drawer.content.tabPanels.importDivulgationRules
    .onImportDivulgationRules.error.title]: 'Error creating divulgation rules!',
  [slotsIntlIds.divulgateSlots.drawer.content.tabPanels.importDivulgationRules
    .onImportDivulgationRules.error.description]:
    'There was a problem creating the divulgation rules. Please check the imported data and try again.',

  [slotsIntlIds.divulgateSlots.drawer.content.tabPanels.divulgateSlots.title]:
    'Divulgate slots',
  [slotsIntlIds.divulgateSlots.drawer.content.tabPanels.divulgateSlots
    .description]:
    'Check the current divulgation rules before confirming the release of slots to Riders and, if you need to change them, import a new spreadsheet with the slots and divulgation rules you want.',
  [slotsIntlIds.divulgateSlots.drawer.content.tabPanels.divulgateSlots
    .divulgationRulesStatus.noRulesImported.label]:
    'There are no divulgation rules created',
  [slotsIntlIds.divulgateSlots.drawer.content.tabPanels.divulgateSlots
    .divulgationRulesStatus.rulesBeingImported.label]:
    'Wait! Divulgation rules are being updated',
  [slotsIntlIds.divulgateSlots.drawer.content.tabPanels.divulgateSlots
    .divulgationRulesStatus.lastRulesImportedAt.label]:
    'Last upload made on {date} at {time}',

  [slotsIntlIds.divulgateSlots.drawer.content.tabPanels.divulgateSlots
    .divulgationRulesSummary.title]: 'Divulgation rules',
  [slotsIntlIds.divulgateSlots.drawer.content.tabPanels.divulgateSlots
    .divulgationRulesSummary.slotsDates.label]: 'Slots dates',
  [slotsIntlIds.divulgateSlots.drawer.content.tabPanels.divulgateSlots
    .divulgationRulesSummary.slotsDates.value]: 'of {month}',
  [slotsIntlIds.divulgateSlots.drawer.content.tabPanels.divulgateSlots
    .divulgationRulesSummary.totalDates.label]: 'Total dates',
  [slotsIntlIds.divulgateSlots.drawer.content.tabPanels.divulgateSlots
    .divulgationRulesSummary.totalDates.value]: `{totalDates, plural,
      =0 {# days}
      one {# day}
      other {# different days}
    }`,
  [slotsIntlIds.divulgateSlots.drawer.content.tabPanels.divulgateSlots
    .divulgationRulesSummary.totalSlots.label]: 'Total slots',
  [slotsIntlIds.divulgateSlots.drawer.content.tabPanels.divulgateSlots
    .divulgationRulesSummary.totalSlots.value]: `{totalSlots, plural,
      =0 {# slots}
      one {# slot}
      other {# slots}
    }`,
  [slotsIntlIds.divulgateSlots.drawer.content.tabPanels.divulgateSlots
    .divulgationRulesSummary.totalHubs.label]: 'Total hubs',
  [slotsIntlIds.divulgateSlots.drawer.content.tabPanels.divulgateSlots
    .divulgationRulesSummary.totalHubs.value]: `{totalHubs, plural,
      =0 {# hubs}
      one {# hub}
      other {# hubs}
    }`,
  [slotsIntlIds.divulgateSlots.drawer.content.tabPanels.divulgateSlots
    .divulgationRulesSummary.shifts.label]: 'Shifts',
  [slotsIntlIds.divulgateSlots.drawer.content.tabPanels.divulgateSlots
    .divulgationRulesSummary.modalities.label]: 'Modalities',
  [slotsIntlIds.divulgateSlots.drawer.content.tabPanels.divulgateSlots
    .divulgationRulesSummary.medals.label]: 'Medals',

  [slotsIntlIds.divulgateSlots.drawer.content.tabPanels.divulgateSlots.footer
    .cancelDivulgateSlotsButton.label]: 'Cancel',
  [slotsIntlIds.divulgateSlots.drawer.content.tabPanels.divulgateSlots.footer
    .confirmDivulgateSlotsButton.label]: 'Divulgate slots',

  [slotsIntlIds.divulgateSlots.drawer.content.tabPanels.divulgateSlots
    .confirmDivulgatePlanningModal.title]: 'Confirm the slots divulgation?',
  [slotsIntlIds.divulgateSlots.drawer.content.tabPanels.divulgateSlots
    .confirmDivulgatePlanningModal
    .description]: `Upon confirmation, the vacancies will be published and made available for reservation. Riders in the segment will be notified.\nWe advise you to review before confirming.`,
  [slotsIntlIds.divulgateSlots.drawer.content.tabPanels.divulgateSlots
    .confirmDivulgatePlanningModal.confirmAndDivulgateButton.label]:
    'Confirm and divulgate',
  [slotsIntlIds.divulgateSlots.drawer.content.tabPanels.divulgateSlots
    .confirmDivulgatePlanningModal.cancelButton.label]: 'Cancel',

  [slotsIntlIds.divulgateSlots.drawer.content.tabPanels.divulgateSlots
    .onDivulgatePlanning.success.title]:
    'Slots divulgation successfully completed!',
  [slotsIntlIds.divulgateSlots.drawer.content.tabPanels.divulgateSlots
    .onDivulgatePlanning.success.description]:
    'Now just wait for Riders to book',
  [slotsIntlIds.divulgateSlots.drawer.content.tabPanels.divulgateSlots
    .onDivulgatePlanning.error.title]: 'Error to divulgate slots!',
  [slotsIntlIds.divulgateSlots.drawer.content.tabPanels.divulgateSlots
    .onDivulgatePlanning.error.description]: 'Please, try again',

  [slotsIntlIds.divulgationSteps.label]: 'Follow the divulgation steps',
  [slotsIntlIds.divulgationSteps.chevron.label]: 'Right arrow',
  [slotsIntlIds.divulgationSteps.createSlot.label]: 'Create the slots',
  [slotsIntlIds.divulgationSteps.createSlot.description]:
    'Import a spreadsheet or manually create unassigned slots',
  [slotsIntlIds.divulgationSteps.importRules.label]: 'Import the rules',
  [slotsIntlIds.divulgationSteps.importRules.description]:
    'Import a spreadsheet with the created slots and each divulgation rule',
  [slotsIntlIds.divulgationSteps.divulgateSlots.label]: 'Divulgate the slots',
  [slotsIntlIds.divulgationSteps.divulgateSlots.description]:
    'Check the rules and confirm the divulgation to Riders',
};
