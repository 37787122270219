import { shiftsIntlIds } from './shifts.ids';

export const shiftsLangEsMx = {
  [shiftsIntlIds.seo.pages.shiftsList.title]: 'Lista de Turnos',
  [shiftsIntlIds.seo.pages.shiftsList.description]:
    'Aplicación web para la gestión de Riders y Entregas de última milla',

  [shiftsIntlIds.shiftsList.header.title]: 'Turnos',
  [shiftsIntlIds.shiftsList.header.createButton.label]: 'Crear turno',

  [shiftsIntlIds.shiftsList.header.tabs.all.label]: 'Todos',

  [shiftsIntlIds.shiftsList.table.cell.header.id]: 'ID Turno',
  [shiftsIntlIds.shiftsList.table.cell.header.name]: 'Turno',
  [shiftsIntlIds.shiftsList.table.cell.header.startTime]: 'Hora de inicio',
  [shiftsIntlIds.shiftsList.table.cell.header.endTime]: 'Hora de finalización',
  [shiftsIntlIds.shiftsList.table.cell.header.duration]: 'Duración',
  [shiftsIntlIds.shiftsList.table.cell.header.createdAt]: 'Creado en',

  [shiftsIntlIds.shiftsList.table.noShiftsFound.label]: 'No hay turnos activos',

  [shiftsIntlIds.shiftsList.toasts.onSuccess.title]:
    '¡El turno se creó exitosamente!',
  [shiftsIntlIds.shiftsList.toasts.onSuccess.description]:
    'Ahora solo crea vacantes y asígnalas a este turno',
  [shiftsIntlIds.shiftsList.toasts.onError.title]: 'Error al crear el turno!',
  [shiftsIntlIds.shiftsList.toasts.onError.description]:
    'Por favor, inténtalo de nuevo',

  [shiftsIntlIds.createShift.drawer.title]: 'Crear turno',
  [shiftsIntlIds.createShift.drawer.closeDrawerButton.label]: 'Cerrar drawer',
  [shiftsIntlIds.createShift.drawer.content.header.title]: 'Nuevo turno',
  [shiftsIntlIds.createShift.drawer.content.header.description]:
    'Cree un nuevo turno a la vez completando los detalles a continuación. Se puede utilizar al crear nuevas vacantes.',

  [shiftsIntlIds.createShift.drawer.content.shiftCharacteristics.icon.title]:
    'Icono de calendario',
  [shiftsIntlIds.createShift.drawer.content.shiftCharacteristics.title]:
    'Definir las características del turno',
  [shiftsIntlIds.createShift.drawer.content.shiftCharacteristics.description]:
    'Definir el nombre que se utilizará para asignar vacantes internamente. Riders no verán este nombre.',
  [shiftsIntlIds.createShift.drawer.content.shiftCharacteristics.shiftName.input
    .label]: 'Nombre del turno',
  [shiftsIntlIds.createShift.drawer.content.shiftCharacteristics.shiftName.input
    .placeholder]: 'Ej.: M1, T1, N1',
  [shiftsIntlIds.createShift.drawer.content.shiftCharacteristics.shiftName.input
    .errors.invalidName]: `{type, select,
      NAME_ALREADY_IN_USE {Ya existe un turno con este nombre}
      other {El nombre del turno debe tener entre 2 y 20 caracteres}
    }`,

  [shiftsIntlIds.createShift.drawer.content.shiftPeriod.icon.title]:
    'Icono de reloj',
  [shiftsIntlIds.createShift.drawer.content.shiftPeriod.title]:
    'Definir el período del turno',
  [shiftsIntlIds.createShift.drawer.content.shiftPeriod.description]:
    'Indicar la hora de inicio y finalización del turno. Recuerda que este es el periodo en el que los Riders elegirán las reservas.',
  [shiftsIntlIds.createShift.drawer.content.shiftPeriod.shiftStartTime.input
    .label]: 'Hora de inicio',
  [shiftsIntlIds.createShift.drawer.content.shiftPeriod.shiftEndTime.input
    .label]: 'Hora de finalización',
  [shiftsIntlIds.createShift.drawer.content.shiftPeriod.shiftEndTime.input
    .errors.invalidPeriod]: `{type, select,
      PERIOD_ALREADY_IN_USE {Este turno ya existe: {shiftName}}
      other {El turno debe durar un mínimo de 30 minutos}
  }`,

  [shiftsIntlIds.createShift.drawer.content.footer.confirmCreateShiftButton
    .label]: 'Crear turno',
  [shiftsIntlIds.createShift.drawer.content.footer.cancelCreateShiftButton
    .label]: 'Cancelar',
};
