import { fromPromise } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { hermesLogger } from '@common/observability';
import { AuthHandler } from '@common/uaa/utils/auth-handler';

import { isExpiredTokenError } from './utils';

let isRefreshing = false;

const setIsRefreshing = (value: boolean) => {
  isRefreshing = value;
};

let myPromise: Promise<unknown> = Promise.resolve();

export const refreshTokenMiddleware = onError(
  ({ graphQLErrors, operation, forward }) => {
    if (graphQLErrors && isExpiredTokenError(graphQLErrors)) {
      hermesLogger.info('[ExpiredTokenError]', { operation });

      if (!isRefreshing) {
        setIsRefreshing(true);

        myPromise = AuthHandler.refreshTokens();

        myPromise.then(() => {
          operation.setContext({
            headers: {
              ...operation.getContext().headers,
              authorization: AuthHandler.authorization,
            },
          });

          setIsRefreshing(false);

          return forward(operation);
        });
      }

      return fromPromise(myPromise).flatMap(() => forward(operation));
    }
  }
);
