import { envs } from '@config';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { useRouter } from 'next/router';
import { PropsWithChildren, ReactElement, useEffect, useMemo } from 'react';

import { AuthHandler } from '../utils/auth-handler';

export default function UaaProvider({
  children,
}: PropsWithChildren): ReactElement {
  const router = useRouter();

  const REDIRECT_PATH = '/sign-in';
  const PUBLIC_PATHS = useMemo(() => ['/sign-in', '/qa/sign-in'], []);

  useEffect(
    function onLoadAndCheckIfSignedIn() {
      if (
        !AuthHandler.isAuthenticated &&
        !PUBLIC_PATHS.includes(router?.pathname)
      ) {
        router?.replace(REDIRECT_PATH);
      }
      /**
       * TODO: Pensar em quais momentos vai rodar esse effect
       */
    },
    [router, PUBLIC_PATHS]
  );

  return (
    <GoogleOAuthProvider
      clientId={envs.googleClientId}
      onScriptLoadSuccess={console.log}
      onScriptLoadError={console.log}
    >
      {children}
    </GoogleOAuthProvider>
  );
}
