import { uploadFileIntlIds } from './upload-file.ids';

export const uploadFileLangEnUs = {
  [uploadFileIntlIds.label]: 'Drag or search for the filled spreadsheet',
  [uploadFileIntlIds.maxSize]: 'Upload spreadsheets up to 5 MB',

  [uploadFileIntlIds.onUpload.removeFile.label]: 'Remove file',
  [uploadFileIntlIds.onUpload.processing.label]: 'File being uploaded',
  [uploadFileIntlIds.onUpload.success.label]: 'File uploaded successfully!',
  [uploadFileIntlIds.onUpload.exceededSize.label]: 'Error uploading file!',
  [uploadFileIntlIds.onUpload.exceededSize.error.label]:
    'File exceeds 5 MB limit. Send smaller files or split content into chunks',
  [uploadFileIntlIds.onUpload.failed.label]: 'Error uploading file!',
  [uploadFileIntlIds.onUpload.failed.error.label]:
    'An unexpected error occurred. Please upload again',
};
