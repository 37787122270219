import { Circle as ChakraCircle } from '@chakra-ui/react';
import { forwardRef, LegacyRef } from 'react';

import { CircleProps } from './circle.types';

export default forwardRef(function Circle(
  props: CircleProps,
  ref: LegacyRef<HTMLDivElement>
) {
  return <ChakraCircle {...props} ref={ref} />;
});
