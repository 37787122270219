import { uploadFileIntlIds } from './upload-file.ids';

export const uploadFileLangEsMx = {
  [uploadFileIntlIds.label]: 'Arrastre o busque la hoja de cálculo llena',
  [uploadFileIntlIds.maxSize]: 'Cargue hojas de cálculo de hasta 5 MB',

  [uploadFileIntlIds.onUpload.removeFile.label]: 'Eliminar archivo',
  [uploadFileIntlIds.onUpload.processing.label]: 'Archivo que se está cargando',
  [uploadFileIntlIds.onUpload.success.label]: '¡Archivo cargado correctamente!',
  [uploadFileIntlIds.onUpload.exceededSize.label]:
    '¡Error al cargar el archivo!',
  [uploadFileIntlIds.onUpload.exceededSize.error.label]:
    'El archivo excede el límite de 5 MB. Envíe archivos más pequeños o divida el contenido en fragmentos',
  [uploadFileIntlIds.onUpload.failed.label]: '¡Error al cargar el archivo!',
  [uploadFileIntlIds.onUpload.failed.error.label]:
    'Se produjo un error inesperado. Por favor vuelva a subir el archivo',
};
