export const uploadFileIntlIds = {
  label: 'uploadFileIntlIds.label',
  maxSize: 'uploadFileIntlIds.maxSize',

  onUpload: {
    removeFile: {
      label: 'uploadFileIntlIds.onUpload.removeFile.label',
    },
    processing: {
      label: 'uploadFileIntlIds.onUpload.processing.label',
    },
    success: {
      label: 'uploadFileIntlIds.onUpload.success.label',
    },
    exceededSize: {
      label: 'uploadFileIntlIds.onUpload.exceededSize.label',
      error: {
        label: 'uploadFileIntlIds.onUpload.exceededSize.error.label',
      },
    },
    failed: {
      label: 'uploadFileIntlIds.onUpload.failed.label',
      error: {
        label: 'uploadFileIntlIds.onUpload.failed.error.label',
      },
    },
  },
};
