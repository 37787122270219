import { uaaIntlIds } from './uaa.ids';

export const uaaLangEnUs = {
  [uaaIntlIds.signIn.welcomeMessage]: 'Welcome to Raio!',
  [uaaIntlIds.signIn.signInInstruction]: 'Use your Google account to access',
  [uaaIntlIds.signIn.button.label]: 'Enter with Google',
  [uaaIntlIds.signIn.manRidingBikeAlt]:
    'Illustration of a person riding a bicycle with a backpack on his back',

  [uaaIntlIds.signIn.qa.inputs.email.placeholder]: 'Email',
  [uaaIntlIds.signIn.qa.inputs.password.placeholder]: 'Password',
};
