import { uaaIntlIds } from './uaa.ids';

export const uaaLangPtBr = {
  [uaaIntlIds.signIn.welcomeMessage]: 'Boas-vindas ao Raio!',
  [uaaIntlIds.signIn.signInInstruction]:
    'Utilize a sua conta Google para acessar',
  [uaaIntlIds.signIn.button.label]: 'Entrar com o Google',
  [uaaIntlIds.signIn.manRidingBikeAlt]:
    'Ilustração de uma pessoa andando em uma bicicleta com uma mochila nas costas',

  [uaaIntlIds.signIn.qa.inputs.email.placeholder]: 'E-mail',
  [uaaIntlIds.signIn.qa.inputs.password.placeholder]: 'Senha',
};
