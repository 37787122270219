import { FormatDateOptions } from '@common/i18n';

type GroupDatesByMonthArgs = {
  dates: string[];
  formatDate: (
    value: string | number | Date | undefined,
    opts?: FormatDateOptions | undefined
  ) => string;
};

export function groupDatesByMonth({
  dates,
  formatDate,
}: GroupDatesByMonthArgs) {
  const groupedByMonth = dates.reduce((acc: Record<string, string[]>, date) => {
    const parsedDate = new Date(date);

    if (isNaN(parsedDate.getTime())) {
      return acc;
    }

    const month = formatDate(parsedDate, { month: 'long' });
    const day = formatDate(parsedDate, { day: '2-digit' });

    acc[month] = acc[month] ? [...acc[month], day] : [day];

    return acc;
  }, {});

  return Object.entries(groupedByMonth);
}
