import { shiftsIntlIds } from './shifts.ids';

export const shiftsLangPtBr = {
  [shiftsIntlIds.seo.pages.shiftsList.title]: 'Lista de Turnos',
  [shiftsIntlIds.seo.pages.shiftsList.description]:
    'Aplicação web para gerenciamento de Riders e Entregas de última milha',

  [shiftsIntlIds.shiftsList.header.title]: 'Turnos',
  [shiftsIntlIds.shiftsList.header.createButton.label]: 'Criar turno',

  [shiftsIntlIds.shiftsList.header.tabs.all.label]: 'Todos',

  [shiftsIntlIds.shiftsList.table.cell.header.id]: 'ID Turno',
  [shiftsIntlIds.shiftsList.table.cell.header.name]: 'Turno',
  [shiftsIntlIds.shiftsList.table.cell.header.startTime]: 'Horário de início',
  [shiftsIntlIds.shiftsList.table.cell.header.endTime]: 'Horário de término',
  [shiftsIntlIds.shiftsList.table.cell.header.duration]: 'Duração',
  [shiftsIntlIds.shiftsList.table.cell.header.createdAt]: 'Criado em',

  [shiftsIntlIds.shiftsList.table.noShiftsFound.label]: 'Não há turnos ativos',

  [shiftsIntlIds.shiftsList.toasts.onSuccess.title]:
    'Turno criado com sucesso!',
  [shiftsIntlIds.shiftsList.toasts.onSuccess.description]:
    'Agora basta criar as vagas e atribuir a este turno',
  [shiftsIntlIds.shiftsList.toasts.onError.title]: 'Erro ao criar o turno!',
  [shiftsIntlIds.shiftsList.toasts.onError.description]:
    'Por favor, tente novamente',

  [shiftsIntlIds.createShift.drawer.title]: 'Criar turno',
  [shiftsIntlIds.createShift.drawer.closeDrawerButton.label]: 'Fechar drawer',
  [shiftsIntlIds.createShift.drawer.content.header.title]: 'Novo turno',
  [shiftsIntlIds.createShift.drawer.content.header.description]:
    'Crie um turno novo por vez, preenchendo os dados abaixo. Ele poderá ser utilizado no momento da criação de novas vagas.',

  [shiftsIntlIds.createShift.drawer.content.shiftCharacteristics.icon.title]:
    'Ícone de calendário',
  [shiftsIntlIds.createShift.drawer.content.shiftCharacteristics.title]:
    'Defina as características do turno',
  [shiftsIntlIds.createShift.drawer.content.shiftCharacteristics.description]:
    'Defina o nome que será utilizado para atribuir vagas internamente. As pessoas Riders não verão este nome.',
  [shiftsIntlIds.createShift.drawer.content.shiftCharacteristics.shiftName.input
    .label]: 'Nome do turno',
  [shiftsIntlIds.createShift.drawer.content.shiftCharacteristics.shiftName.input
    .placeholder]: 'Ex.: M1, T1, N1',
  [shiftsIntlIds.createShift.drawer.content.shiftCharacteristics.shiftName.input
    .errors.invalidName]: `{type, select,
      NAME_ALREADY_IN_USE {Já existe um turno com este nome}
      other {O nome do turno deve ter entre 2 e 20 caracteres}
    }`,

  [shiftsIntlIds.createShift.drawer.content.shiftPeriod.icon.title]:
    'Ícone de relógio',
  [shiftsIntlIds.createShift.drawer.content.shiftPeriod.title]:
    'Defina o período do turno',
  [shiftsIntlIds.createShift.drawer.content.shiftPeriod.description]:
    'Indique o horário de início e término do turno. Lembre-se que este é o período o qual as pessoas Riders escolherão as reservas.',
  [shiftsIntlIds.createShift.drawer.content.shiftPeriod.shiftStartTime.input
    .label]: 'Horário de início',
  [shiftsIntlIds.createShift.drawer.content.shiftPeriod.shiftEndTime.input
    .label]: 'Horário de término',
  [shiftsIntlIds.createShift.drawer.content.shiftPeriod.shiftEndTime.input
    .errors.invalidPeriod]: `{type, select,
      PERIOD_ALREADY_IN_USE {Este turno já existe: {shiftName}}
      other {O turno deve possuir a duração mínima de 30 minutos}
    }`,

  [shiftsIntlIds.createShift.drawer.content.footer.confirmCreateShiftButton
    .label]: 'Criar turno',
  [shiftsIntlIds.createShift.drawer.content.footer.cancelCreateShiftButton
    .label]: 'Cancelar',
};
