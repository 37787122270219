import { countdownLangEnUs } from '@common/core-components/countdown/intl';
import { deliverySearchInputLangEnUs } from '@common/core-components/delivery-search-input/intl';
import { hubsMultiSelectLangEnUs } from '@common/core-components/hubs-multi-select/intl';
import { manualOrAutomaticRiderAssignmentLangEnUs } from '@common/core-components/manual-or-automatic-rider-assignment/intl';
import { modalsLangEnUs } from '@common/core-components/modals/intl/en-US';
import { paginationLangEnUs } from '@common/core-components/pagination/intl';
import { statusTagLangEnUs } from '@common/core-components/status-tag/intl/en-US';
import { toastsLangEnUs } from '@common/core-components/toasts/intl';
import { tripInfosLangEnUs } from '@common/core-components/trip-infos/intl/en-US';
import { uploadFileLangEnUs } from '@common/core-components/upload-file/intl';
import { dashboardLayoutLangEnUs } from '@common/dashboard-layout/intl';
import { deliveriesLangEnUs } from '@modules/deliveries/intl';
import { errorsLangEnUs } from '@modules/errors/intl';
import { hubsLangEnUs } from '@modules/hubs/intl';
import { journeysLangEnUs } from '@modules/journeys/intl';
import { shiftsLangEnUs } from '@modules/reservaki/shifts/intl';
import { slotsLangEnUs } from '@modules/reservaki/slots/intl';
import { ridersLangEnUs } from '@modules/riders/intl';
import { tripsLangEnUs } from '@modules/trips/intl';
import { uaaLangEnUs } from '@modules/uaa/intl';

const enUsMessages = {
  ...dashboardLayoutLangEnUs,
  ...deliveriesLangEnUs,
  ...paginationLangEnUs,
  ...journeysLangEnUs,
  ...uaaLangEnUs,
  ...errorsLangEnUs,
  ...hubsMultiSelectLangEnUs,
  ...countdownLangEnUs,
  ...hubsLangEnUs,
  ...ridersLangEnUs,
  ...statusTagLangEnUs,
  ...shiftsLangEnUs,
  ...tripsLangEnUs,
  ...modalsLangEnUs,
  ...toastsLangEnUs,
  ...tripsLangEnUs,
  ...manualOrAutomaticRiderAssignmentLangEnUs,
  ...tripInfosLangEnUs,
  ...deliverySearchInputLangEnUs,
  ...slotsLangEnUs,
  ...uploadFileLangEnUs,
};

export default enUsMessages;
