import { dashboardLayoutIntlIds } from './dashboard-layout';

export const dashboardLayoutLangPtBr = {
  [dashboardLayoutIntlIds.sidebar.navigation.scope.label]:
    '{scope, select, planning {Planejamento} other {}}',

  [dashboardLayoutIntlIds.sidebar.navigation.items.deliveries.label]:
    'Entregas: Lista',
  [dashboardLayoutIntlIds.sidebar.navigation.items.riders.availability.label]:
    'Rider: Disponibilidade',
  [dashboardLayoutIntlIds.sidebar.navigation.items.riders.management.label]:
    'Rider: Lista',
  [dashboardLayoutIntlIds.sidebar.navigation.items.riders.shifts.label]:
    'Rider: Turnos',
  [dashboardLayoutIntlIds.sidebar.navigation.items.riders.slots.label]:
    'Rider: Vagas',
  [dashboardLayoutIntlIds.sidebar.navigation.items.trips.label]:
    'Viagens: Lista',
  [dashboardLayoutIntlIds.sidebar.navigation.items.hubs.label]: 'Hubs: Lista',

  [dashboardLayoutIntlIds.sidebar.profile.userName]: 'Pessoa desconhecida',
  [dashboardLayoutIntlIds.sidebar.profile.items.signOut]: 'Sair',
};
