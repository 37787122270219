import { slotsIntlIds } from './slots.ids';

export const slotsLangEsMx = {
  [slotsIntlIds.slotsList.header.createSlotsButton.label]: 'Crear vacantes',
  [slotsIntlIds.slotsList.header.divulgateSlotsButton.label]:
    'Divulgar vacantes',

  [slotsIntlIds.createSlots.drawer.title]: 'Crear vacantes',
  [slotsIntlIds.createSlots.drawer.closeDrawerButton.label]: 'Cerrar drawer',

  [slotsIntlIds.createSlots.drawer.content.tabs.importSlots.label]:
    'Importar vacantes',
  [slotsIntlIds.createSlots.drawer.content.tabs.createUnitarySlot.label]:
    'Crear una vacante',

  [slotsIntlIds.createSlots.drawer.content.tabPanels.importSlots.title]:
    'Importar vacantes',
  [slotsIntlIds.createSlots.drawer.content.tabPanels.importSlots
    .description]: `Cargue la hoja de cálculo .csv completa con las vacantes que desea crear. Los campos fecha, centro, turno y número de plazas son obligatorios, por lo que no se aceptarán hojas de cálculo con otras columnas.\nConsulta los nombres de turnos disponibles <link>aquí</link> para rellenar la hoja de cálculo y, si es necesario, crear un nuevo turno antes de crear las vacantes.`,

  [slotsIntlIds.createSlots.drawer.content.tabPanels.importSlots
    .confirmImportSlotsModal.title]:
    '¿Confirmar la importación de la hoja de cálculo de vacantes?',
  [slotsIntlIds.createSlots.drawer.content.tabPanels.importSlots
    .confirmImportSlotsModal.description]:
    'La hoja de cálculo se importará y procesará. Tras la validación, se crearán las vacantes según los datos enviados. Esta acción no se puede deshacer.',
  [slotsIntlIds.createSlots.drawer.content.tabPanels.importSlots
    .confirmImportSlotsModal.confirmAndImportButton.label]:
    'Confirmar e importar',
  [slotsIntlIds.createSlots.drawer.content.tabPanels.importSlots
    .confirmImportSlotsModal.cancelButton.label]: 'Cancelar',

  [slotsIntlIds.createSlots.drawer.content.tabPanels.importSlots
    .cancelImportSlotsModal.title]: '¿Seguro que quieres salir?',
  [slotsIntlIds.createSlots.drawer.content.tabPanels.importSlots
    .cancelImportSlotsModal.description]:
    'No se guardaron los cambios y tendrás que repetirlos desde el principio.',
  [slotsIntlIds.createSlots.drawer.content.tabPanels.importSlots
    .cancelImportSlotsModal.stayAndContinueButton.label]: 'Quédate y continúa',
  [slotsIntlIds.createSlots.drawer.content.tabPanels.importSlots
    .cancelImportSlotsModal.leaveButton.label]: 'Salir',

  [slotsIntlIds.createSlots.drawer.content.tabPanels.importSlots.footer
    .cancelImportSlotsButton.label]: 'Cancelar',
  [slotsIntlIds.createSlots.drawer.content.tabPanels.importSlots.footer
    .confirmImportSlotsButton.label]: 'Importar vacantes',

  [slotsIntlIds.createSlots.drawer.content.tabPanels.importSlots.onImportSlots
    .success.title]: '¡Vacantes creadas y disponibles para divulgación!',
  [slotsIntlIds.createSlots.drawer.content.tabPanels.importSlots.onImportSlots
    .success.description]:
    'Ahora simplemente importa las reglas de divulgación para que los Riders puedan reservarlas',
  [slotsIntlIds.createSlots.drawer.content.tabPanels.importSlots.onImportSlots
    .error.title]: '¡Error al crear vacantes para divulgación!',
  [slotsIntlIds.createSlots.drawer.content.tabPanels.importSlots.onImportSlots
    .error.description]:
    'Hubo un problema al crear las vacantes. Por favor verifique los datos importados y vuelva a intentarlo.',

  [slotsIntlIds.divulgateSlots.drawer.title]: 'Divulgar vacantes',
  [slotsIntlIds.divulgateSlots.drawer.closeDrawerButton.label]: 'Cerrar drawer',

  [slotsIntlIds.divulgateSlots.drawer.content.tabs.importDivulgationRules
    .label]: 'Importar reglas de divulgación',
  [slotsIntlIds.divulgateSlots.drawer.content.tabs.divulgateSlots.label]:
    'Divulgar vacantes',

  [slotsIntlIds.divulgateSlots.drawer.content.tabPanels.importDivulgationRules
    .title]: 'Importar reglas de divulgación',
  [slotsIntlIds.divulgateSlots.drawer.content.tabPanels.importDivulgationRules
    .description]: `Sube la planilla .csv con las reglas de divulgación que deseas divulgar. Los campos fecha, centro, turno, modalidad, medalla y número de plazas son obligatorios, por lo que no se aceptarán planillas con otras columnas.
\nConsulta las vacantes creados <link>aquí</link> para rellenar la planilla y, si es necesario, crear nuevas vacantes y añadirlos a la planilla antes de importarla.`,
  [slotsIntlIds.divulgateSlots.drawer.content.tabPanels.importDivulgationRules
    .footer.cancelImportDivulgationRulesButton.label]: 'Cancelar',
  [slotsIntlIds.divulgateSlots.drawer.content.tabPanels.importDivulgationRules
    .footer.confirmImportDivulgationRulesButton.label]: 'Importar reglas',

  [slotsIntlIds.divulgateSlots.drawer.content.tabPanels.importDivulgationRules
    .onImportDivulgationRules.success.title]:
    '¡Reglas de divulgación creadas y disponibles para divulgación!',
  [slotsIntlIds.divulgateSlots.drawer.content.tabPanels.importDivulgationRules
    .onImportDivulgationRules.success.description]:
    'Ahora solo divulguelas para que los Riders puedan reservarlas',
  [slotsIntlIds.divulgateSlots.drawer.content.tabPanels.importDivulgationRules
    .onImportDivulgationRules.error.title]:
    '¡Error al crear las reglas de divulgación!',
  [slotsIntlIds.divulgateSlots.drawer.content.tabPanels.importDivulgationRules
    .onImportDivulgationRules.error.description]:
    'Se produjo un problema al crear las reglas de divulgación. Por favor, revise los datos importados y vuelva a intentarlo.',

  [slotsIntlIds.divulgateSlots.drawer.content.tabPanels.divulgateSlots.title]:
    'Divulgar vacantes',
  [slotsIntlIds.divulgateSlots.drawer.content.tabPanels.divulgateSlots
    .description]:
    'Verifique las reglas de divulgación actuales antes de confirmar la liberación de vacantes a los Riders y, si necesita cambiarlas, importe una nueva hoja de cálculo con las vacantes y las reglas de divulgación que desee.',
  [slotsIntlIds.divulgateSlots.drawer.content.tabPanels.divulgateSlots
    .divulgationRulesStatus.noRulesImported.label]:
    'No se han creado reglas de divulgación',
  [slotsIntlIds.divulgateSlots.drawer.content.tabPanels.divulgateSlots
    .divulgationRulesStatus.rulesBeingImported.label]:
    '¡Espere! Las reglas de divulgación se están actualizando',
  [slotsIntlIds.divulgateSlots.drawer.content.tabPanels.divulgateSlots
    .divulgationRulesStatus.lastRulesImportedAt.label]:
    'La última carga se realizó el {date} a las {time}',

  [slotsIntlIds.divulgateSlots.drawer.content.tabPanels.divulgateSlots
    .divulgationRulesSummary.title]: 'Reglas de divulgación',
  [slotsIntlIds.divulgateSlots.drawer.content.tabPanels.divulgateSlots
    .divulgationRulesSummary.slotsDates.label]: 'Fechas de las vacantes',
  [slotsIntlIds.divulgateSlots.drawer.content.tabPanels.divulgateSlots
    .divulgationRulesSummary.slotsDates.value]: 'de {month}',
  [slotsIntlIds.divulgateSlots.drawer.content.tabPanels.divulgateSlots
    .divulgationRulesSummary.totalDates.label]: 'Fechas totales',
  [slotsIntlIds.divulgateSlots.drawer.content.tabPanels.divulgateSlots
    .divulgationRulesSummary.totalDates.value]: `{totalDates, plural,
      =0 {# días}
      one {# día}
      other {# días diferentes}
    }`,
  [slotsIntlIds.divulgateSlots.drawer.content.tabPanels.divulgateSlots
    .divulgationRulesSummary.totalSlots.label]: 'Total de vacantes',
  [slotsIntlIds.divulgateSlots.drawer.content.tabPanels.divulgateSlots
    .divulgationRulesSummary.totalSlots.value]: `{totalSlots, plural,
      =0 {# vacantes}
      one {# vacante}
      other {# vacantes}
    }`,
  [slotsIntlIds.divulgateSlots.drawer.content.tabPanels.divulgateSlots
    .divulgationRulesSummary.totalHubs.label]: 'Total de hubs',
  [slotsIntlIds.divulgateSlots.drawer.content.tabPanels.divulgateSlots
    .divulgationRulesSummary.totalHubs.value]: `{totalHubs, plural,
      =0 {# hubs}
      one {# hub}
      other {# hubs}
    }`,
  [slotsIntlIds.divulgateSlots.drawer.content.tabPanels.divulgateSlots
    .divulgationRulesSummary.shifts.label]: 'Turnos',
  [slotsIntlIds.divulgateSlots.drawer.content.tabPanels.divulgateSlots
    .divulgationRulesSummary.modalities.label]: 'Modalidades',
  [slotsIntlIds.divulgateSlots.drawer.content.tabPanels.divulgateSlots
    .divulgationRulesSummary.medals.label]: 'Medallas',

  [slotsIntlIds.divulgateSlots.drawer.content.tabPanels.divulgateSlots.footer
    .cancelDivulgateSlotsButton.label]: 'Cancelar',
  [slotsIntlIds.divulgateSlots.drawer.content.tabPanels.divulgateSlots.footer
    .confirmDivulgateSlotsButton.label]: 'Divulgar vacantes',

  [slotsIntlIds.divulgateSlots.drawer.content.tabPanels.divulgateSlots
    .confirmDivulgatePlanningModal.title]:
    '¿Confirmar la divulgación de las tragamonedas?',
  [slotsIntlIds.divulgateSlots.drawer.content.tabPanels.divulgateSlots
    .confirmDivulgatePlanningModal.description]:
    'Tras la confirmación, las vacantes se publicarán y estarán disponibles para reservar. Se notificará a los Riders del segmento.\nLe recomendamos revisar la información antes de confirmar.',
  [slotsIntlIds.divulgateSlots.drawer.content.tabPanels.divulgateSlots
    .confirmDivulgatePlanningModal.confirmAndDivulgateButton.label]:
    'Confirmar y divulgar',
  [slotsIntlIds.divulgateSlots.drawer.content.tabPanels.divulgateSlots
    .confirmDivulgatePlanningModal.cancelButton.label]: 'Cancelar',

  [slotsIntlIds.divulgateSlots.drawer.content.tabPanels.divulgateSlots
    .onDivulgatePlanning.success.title]:
    '¡Divulgación de vacantes completada exitosamente!',
  [slotsIntlIds.divulgateSlots.drawer.content.tabPanels.divulgateSlots
    .onDivulgatePlanning.success.description]:
    'Ahora sólo espera a que los Riders reserven',
  [slotsIntlIds.divulgateSlots.drawer.content.tabPanels.divulgateSlots
    .onDivulgatePlanning.error.title]: '¡Error al divulgar vacantes!',
  [slotsIntlIds.divulgateSlots.drawer.content.tabPanels.divulgateSlots
    .onDivulgatePlanning.error.description]: 'Por favor, inténtalo de nuevo',

  [slotsIntlIds.divulgationSteps.label]: 'Siga los pasos de divulgación',
  [slotsIntlIds.divulgationSteps.chevron.label]: 'Flecha derecha',
  [slotsIntlIds.divulgationSteps.createSlot.label]: 'Crear las vacantes',
  [slotsIntlIds.divulgationSteps.createSlot.description]:
    'Importar una hoja de cálculo o crear manualmente vacantes no asignadas',
  [slotsIntlIds.divulgationSteps.importRules.label]: 'Importar las reglas',
  [slotsIntlIds.divulgationSteps.importRules.description]:
    'Importar una hoja de cálculo con las vacantes creadas y cada regla de divulgación',
  [slotsIntlIds.divulgationSteps.divulgateSlots.label]: 'Divulgar las vacantes',
  [slotsIntlIds.divulgationSteps.divulgateSlots.description]:
    'Verificar las reglas y confirmar la divulgación a los Riders',
};
